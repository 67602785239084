<template>
  <div class="login">
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          alt="Kakimoto Logo"
          class="shrink mr-2"
          contain
          src="@/assets/favicon-196x196.png"
          transition="scale-transition"
          width="40"
        />
      </div>
      <v-toolbar-title>監視番カメラ</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-switch
        inset
        dense
        dark
        color="success"
        v-model="autoUpdateImage"
        :messages="`自動更新:${autoUpdateImage ? 'ON' : 'OFF'}`"
        @change="latestImageCountID=handleAutoUpdate(autoUpdateImage)"
      ></v-switch>
      <div v-if="windowSize === 1">
        <v-btn
          x-small
          rounded
          color="primary"
          @click="getImage"
        >
          <v-icon left>
            mdi-restore
          </v-icon>
          最新画像取得
        </v-btn>
      </div>
      <div v-else>
        <v-btn
          rounded
          color="primary"
          @click="getImage"
        >
          <v-icon left>
            mdi-restore
          </v-icon>
          最新画像取得
        </v-btn>
      </div>
      
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      left
      temporary
      fixed
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title 
              @click="logoutDialog = true"
            >
              ログアウト
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    
    <v-dialog
      v-model="logoutDialog"
      persistent
      max-width="750"
    >
      <v-card>
        <v-card-title>ログアウト</v-card-title>
        <v-card-text>
          ログアウトしてよろしいでしょうか。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="logoutDialog = false"
          >
            キャンセル
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="submit"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container>
      <v-data-iterator
        :items="latestImg"
        item-key="name"
        :items-per-page="8"
        sort-by="showIndex"
        loading
        loading-text="データを読み込み中です。"
        locale="ja-JP"
        hide-default-footer
      >
        <template v-slot:default="{ items }">
          <v-row
            dense
          >
            <v-col
              v-for="item in items"
              :key="item.showIndex"
              cols="12"
              xs="6"
              sm="6"
              md="4"
              lg="4"
            >
              <v-card
                outlined
                tile
              >
                <v-card-title>{{ item.deviceName }}</v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item>
                    <v-img
                      v-bind:src="'data:image/jpeg;base64,' + item.image"
                      @click="getHighResoImage(item.deviceID, item.deviceName)"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item>
                    <div v-if="item.archiveSeconds !== 0">
                      <v-card-actions>
                        <div v-if="windowSize === 1">
                          <v-btn 
                            x-small
                            class="info"
                            @click="() => {
                              archiveSelectDevice = item.deviceID;
                              archiveSelectDialog = true;
                            }"
                          >ｱｰｶｲﾌﾞ表示</v-btn>
                        </div>
                        <div v-else>
                          <v-btn 
                            class="info"
                            @click="() => {
                              archiveSelectDevice = item.deviceID;
                              archiveSelectDialog = true;
                            }"
                          >ｱｰｶｲﾌﾞ表示</v-btn>
                        </div>
                      </v-card-actions>
                    </div>
                    <v-card-actions>
                      <div v-if="windowSize === 1">
                        <v-btn
                          x-small 
                          class="info"
                          @click="downloadImage(item.deviceID, item.deviceName)"
                        >ﾀﾞｳﾝﾛｰﾄﾞ</v-btn>
                      </div>
                      <div v-else>
                        <v-btn
                          class="info"
                          @click="downloadImage(item.deviceID, item.deviceName)"
                        >ﾀﾞｳﾝﾛｰﾄﾞ</v-btn>
                      </div>
                    </v-card-actions>
                  </v-list-item>

                </v-list>
              </v-card>
            </v-col>
          </v-row>
          
          <v-footer
            app
            padless
            outlined
            fixed
          >
            <v-row
              justify="center"
              align="center"
              no-gutters
            >
              <span class="grey--text my-2">画像表示数</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    text
                    color="primary"
                    class="my-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                  >
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-spacer></v-spacer>

              <span
                class="my-2
                grey--text"
              >
                ページ {{ page }} / {{ numberOfPages }}
              </span>
              <div v-if="windowSize === 1">
                <v-btn
                  x-small
                  fab
                  dark
                  color="primary"
                  class="my-2"
                  @click="formerPage"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  fab
                  dark
                  color="primary"
                  class="my-2"
                  @click="formerPage"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </div>
            
              <div v-if="windowSize === 1">
                <v-btn
                  x-small
                  fab
                  dark
                  color="primary"
                  class="my-2"
                  @click="nextPage"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  fab
                  dark
                  color="primary"
                  class="my-2"
                  @click="nextPage"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-row>
          </v-footer>
        </template>
      </v-data-iterator>
      
      <v-dialog
        v-model="dialog"
        persistent
        max-width="750"
      >
        <v-card>
          <v-card-title>{{highResoImg.deviceName}}</v-card-title>
          <v-img
            v-bind:src="'data:image/jpeg;base64,' + highResoImg.image"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
            >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="archiveSelectDialog"
        persistent
        max-width="750"
      >
        <v-card>
          <v-card-title>アーカイブ表示期間指定</v-card-title>
          <v-card-text>
            表示開始日付を指定してください。
          </v-card-text>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-dialog
              ref="startDateDialog"
              v-model="startDateModal"
              :return-value.sync="startDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="表示開始日付"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                scrollable
                :first-day-of-week="0"
                locale="ja"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="startDateModal = false"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.startDateDialog.save(startDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
              ref="startTimeDialog"
              v-model="startTimeModal"
              :return-value.sync="startTime"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startTime"
                  label="表示開始時間"
                  prepend-icon="mdi-clock-outline"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="startTime"
                scrollable
                format="24hr"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="startTimeModal = false"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.startTimeDialog.save(startTime)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-dialog
              ref="endDateDialog"
              v-model="endDateModal"
              :return-value.sync="endDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  label="表示終了日付"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                scrollable
                :first-day-of-week="0"
                locale="ja"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="endDateModal = false"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.endDateDialog.save(endDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
              ref="endTimeDialog"
              v-model="endTimeModal"
              :return-value.sync="endTime"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endTime"
                  label="表示終了時間"
                  prepend-icon="mdi-clock-outline"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="endTime"
                scrollable
                format="24hr"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="endTimeModal = false"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.endTimeDialog.save(endTime)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="archiveSelectDialog = false"
            >
              キャンセル
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="pageSendArchive(archiveSelectDevice, startDate, startTime, endDate, endTime)"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-container>
    
  </div>
</template>

<script>
// @ is an alias to /src
import { Auth } from 'aws-amplify';
import { AmplifyConf } from '../plugins/amplifyConf';

  export default {
    name: 'Login',

    data: () => ({
      latestImg: [],
      highResoImg: {},
      loginStatus: '',
      loading: false,
      cameraList: {},
      dialog: false,
      drawer: false,
      group: null,
      logoutDialog: false,
      latestImageCountID: 0,
      autoUpdateImage: true,
      windowSize: 0,
      itemsPerPageArray: [4, 8, 12],
      page: 1,
      itemsPerPage: 4,
      archiveSelectDevice: '',
      archiveSelectDialog: false,
      startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      startDateModal: false,
      endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      endDateModal: false,
      startTime: (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000) - 3600000)).toISOString().substr(11, 5),
      startTimeModal: false,
      endTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5),
      endTimeModal: false,
    }),
    
    computed: {
      showLatestImg() {
        const imgData = new Image();
        imgData.src = this.latestImg; 
        return imgData;
      },
      numberOfPages () {
        return Math.ceil(this.cameraList.length / this.itemsPerPage)
      },
    },

    beforeCreate: function () {
      AmplifyConf();
      Auth.currentSession()
        .then(() => {
          //console.log ('ログイン済');
        })
        .catch(() => {
          //console.log('ログイン未');
          //this.$router.push('/');
        });
    },

    created: function () {
      this.getCameraList();
      this.latestImageCountID=this.handleAutoUpdate(this.autoUpdateImage); //画面表示後、画像自動更新を切り替える
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },

    beforeDestroy: function () {
      this.$clearInterval(this.latestImageCountID);
    },

    watch: {
      cameraList: 'getImage',
      group () {
        this.drawer = false;
      },
    },

    methods: {
      handleResize() {
        if (window.innerWidth < 600) {
          this.windowSize = 1;
        } else {
          this.windowSize = 0;
        }
      },
      getCameraList() {
        const AuthStorage = this.$store.getters.cognitoAuthStorage;
        const idToken = AuthStorage[Object.keys(AuthStorage).find(x => x.match(/.*idToken$/))]
        const userID = AuthStorage[Object.keys(AuthStorage).find(x => x.match(/.*LastAuthUser$/))]
        this.axios.get(
          'https://7gzqkmw383.execute-api.ap-northeast-1.amazonaws.com/pi-camera/list?userID=' + userID,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': idToken,
            }}
          ).then((responce) => {
            //this.$set(this.cameraList, responce.data.body);
            this.cameraList = responce.data.body;
          })
          .catch((e) => {
            console.log(e);
          })
      },
      getImage() {
        this.latestImg.splice(0);
        const AuthStorage = this.$store.getters.cognitoAuthStorage;
        const idToken = AuthStorage[Object.keys(AuthStorage).find(x => x.match(/.*idToken$/))]
        for ( const key in this.cameraList ) {
          const deviceID = this.cameraList[key]['deviceID'];
          const deviceName = this.cameraList[key]['deviceName'];
          const showIndex = this.cameraList[key]['showIndex'];
          const archiveSeconds = this.cameraList[key]['archiveSeconds'];
          this.axios.get(
            'https://7gzqkmw383.execute-api.ap-northeast-1.amazonaws.com/pi-camera/img?deviceID=' + deviceID + '&imgName=latest-img_thumb.jpg',
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': idToken,
            }}
            ).then((responce) => {
              //this.$set(this.latestImg, responce.data.body);
              this.latestImg.push({'deviceID': deviceID, 'deviceName': deviceName, 'image': responce.data.body, 'showIndex': showIndex, 'archiveSeconds': archiveSeconds});
            })
            .catch((e) => {
              console.log(e);
            })
        }
      },
      getHighResoImage(deviceID, deviceName) {
        this.dialog = true
        this.highResoImg = {};
        const AuthStorage = this.$store.getters.cognitoAuthStorage;
        const idToken = AuthStorage[Object.keys(AuthStorage).find(x => x.match(/.*idToken$/))]
        this.axios.get(
          'https://7gzqkmw383.execute-api.ap-northeast-1.amazonaws.com/pi-camera/img?deviceID=' + deviceID + '&imgName=latest-img.jpg',
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': idToken,
          }}
          ).then((responce) => {
            //this.$set(this.latestImg, responce.data.body);
            this.highResoImg = {'deviceID': deviceID, 'deviceName': deviceName, 'image': responce.data.body};
          })
          .catch((e) => {
            console.log(e);
          })
      },
      downloadImage(deviceID, deviceName) {
        const AuthStorage = this.$store.getters.cognitoAuthStorage;
        const idToken = AuthStorage[Object.keys(AuthStorage).find(x => x.match(/.*idToken$/))]
        this.axios.get(
          'https://7gzqkmw383.execute-api.ap-northeast-1.amazonaws.com/pi-camera/img?deviceID=' + deviceID + '&imgName=latest-img.jpg',
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': idToken,
          }}
          ).then((responce) => {
            const data = 'data:image/jpeg;base64,' + responce.data.body;
            let bin = atob(data.replace(/^.*,/, ''))
            let buffer = new Uint8Array(bin.length);
            for (let i = 0; i < bin.length; i++) {
              buffer[i] = bin.charCodeAt(i);
            }
            const blob = new Blob([buffer, buffer], { type: 'image/jpeg' })
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.download = deviceName + '.jpg';
            a.href = url;
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
          })
          .catch((e) => {
            console.log(e);
          })
      },
      handleAutoUpdate(updateOn) {
        let id = 0;
        if (updateOn) {
          id = this.$setInterval(() => { 
            this.getImage();
          }, 5 * 60 * 1000);
        } else {
          this.$clearInterval(this.latestImageCountID);
          id = 0;
        }
        return id;
      },
      pageSendAbout() {
        const v_router = this.$router;
        v_router.push('/about');
      },
      pageSendArchive(selectDeviceID, startDate, startTime, endDate, endTime) {
        const v_router = this.$router;
        v_router.push({ 
          name: 'Archive',
          params: {
            selectDeviceID: selectDeviceID,
            startDate: startDate,
            startTime: startTime,
            endDate: endDate,
            endTime: endTime
          }
        });
      },
      submit() {
        const v_router = this.$router;
        const v_store = this.$store;
        Auth.signOut()
          .then(() => {
            v_store.commit('refresh');
            v_router.push('/');
            //console.log('ログアウトに成功しました');
          })
          .catch((e) => {
            console.log(e);
            //console.log('ログアウトに失敗しました')
          })
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
    },

  }
</script>